import React from "react";
import "./App.css";
import Footer from "./components/footer.js";

function App() {

  return (
    <div className="bg-gradient-to-r from-gray-800 to-cyan-800">
      <div className="App max-w-[1200px] m-auto min-h-screen flex flex-col items-center text-gray-200 p-8">
        <img
          src={`${process.env.PUBLIC_URL}/coming-soon.svg`}
          alt="Coming Soon"
          className="object-cover max-w-full h-auto m-6 transform hover:scale-105 transition-transform duration-200"
        />
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">
          En construcción
        </h1>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl">
          Estamos trabajando para ofrecerte una mejor experiencia. ¡Vuelve pronto!
        </p>
        <Footer />
      </div>
    </div>
  );
}

export default App;
