import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center fixed bottom-0 w-full text-xs">
      &copy; {new Date().getFullYear()} Don Alejandro | Aceite de Oliva. Todos los derechos reservados.
    </footer>
  );
}

export default Footer;